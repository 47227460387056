<template>
    <div class="hero-home theme--dark">
        <div
            class="hero-home__fold"
            :style="`height: ${innerHeight}`"
        >
            <div class="hero-home__main-links">
                <img
                    src="~/assets/img/logos/filmfonds.svg"
                    alt="Filmfonds"
                    height="55"
                    width="100"
                    loading="eager"
                    fetchPriority="high"
                >
                <BigLinks :links="mappedLinks1" />
                <MenuButton
                    class="hero-home__menu-button"
                    aria-hidden="true"
                    tabindex="-1"
                />
            </div>
            <HomeSlider
                v-if="slider.length"
                :items="slider"
                :no-scroll-indicator="noScrollIndicator"
            />
        </div>

        <div class="article hero-home__intro">
            <div
                id="home-hero-anchor"
                class="hero-home__scroll-anchor"
                tabindex="-1"
            />
            <div v-html="intro" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>

        <BigLinks
            class="hero-home__secondary-links"
            :links="mappedLinks2"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    links1: {
        type: Array,
        default: () => [],
    },
    slider: {
        type: Array,
        default: () => [],
    },
    intro: {
        type: String,
        default: '',
    },
    links2: {
        type: Array,
        default: () => [],
    },
});

const { links1, links2 } = toRefs(props);

const innerHeight = ref('100vh');
const noScrollIndicator = ref(false);

const mappedLinks1 = computed(() => {
    return links1.value.map((link) => {
        return {
            label: link?.link?.[0]?.label,
            linkedEntry: link?.link?.[0]?.entry
        };
    });
});

const mappedLinks2 = computed(() => {
    return links2.value.map((link) => {
        return {
            label: link?.link?.[0]?.label,
            linkedEntry: link?.link?.[0]?.entry
        };
    });
});

onMounted(() => {
    updateInnerHeight();
});

function updateInnerHeight() {
    if ('ontouchstart' in document.documentElement) { // ismobile
        innerHeight.value = `${Math.max(window?.innerHeight, 500)}px`;

        if (window?.innerHeight < 585) {
            noScrollIndicator.value = true;
        }
    } else {
        innerHeight.value = '100vh';
    }
}
</script>

<style lang="less" src="./HeroHome.less" />
