<template>
    <div
        class="home-slider"
        :class="{ 'home-slider--visible': isReady, 'home-slider--no-scroll-indicator': noScrollIndicator }"
    >
        <DnSlider
            ref="slider"
            class="home-slider__slider"
            :items="itemsExtended"
            :track-intersection="true"
        >
            <template v-slot:slide="{ item, index, isVisible }">
                <ImageCard
                    :ref="index === middleIndex ? 'middle' : null"
                    :item="item"
                    drag-to-scroll="ignore"
                    draggable="false"
                    :is-visible="isVisible"
                    :tabindex="index > (items.length - 1) ? '-1' : '0'"
                    :aria-hidden="index > (items.length - 1) ? 'true' : 'false'"
                    class="home-slider__card"
                    sizes="(min-width: 768px) 50vw, 100vw"
                    :loading="middleIndex ? 'eager' : 'lazy'"
                    :decoding="middleIndex ? 'auto' : 'async'"
                    :fetch-priority="middleIndex ? 'high' : 'auto'"
                />
            </template>
        </DnSlider>
        <a
            v-if="!noScrollIndicator"
            href="#home-hero-anchor"
            class="button--gesture"
        >
            <div />
            <div class="sr-only">{{ $t('skiplinks.content') }}</div>
            <BaseIcon icon="arrow-down-long" />
        </a>
    </div>
</template>

<script>
import DnSlider from '@digitalnatives/slider';

export default {
    components: {
        DnSlider
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            }
        },
        noScrollIndicator: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isReady: false
        };
    },
    computed: {
        middleIndex() {
            return Math.floor(this.itemsExtended.length / 2);
        },
        itemsExtended() {
            const extendedArray = [...this.items, ...this.items, ...this.items];
            if (extendedArray.length % 2 === 0) {
                extendedArray.pop(); // Make sure there is always a 'middle' item by making it uneven if even
            }

            return extendedArray;
        }
    },
    mounted() {
        this.$nextTick(() => {
            const scrollContainer = this?.$refs?.slider?.$el?.querySelector('.slider__track');

            scrollContainer?.scrollTo({
                left: scrollContainer.scrollWidth / 2
            });

            setTimeout(() => {
                this.isReady = true;
            }, 500);
        });
    }
};
</script>

<style lang="less" src="./HomeSlider.less"></style>
