<template>
    <div>
        <article>
            <HeroHome
                :links1="entry?.heroLinks"
                :slider="entry?.heroSlider"
                :intro="entry?.heroIntro"
                :links2="entry?.heroLinks2"
            />
            <FlexibleSections
                v-if="entry"
                :sections="entry.flexibleSections"
            />
        </article>
    </div>
</template>

<script setup>
import { formatISO } from 'date-fns';
import getPage from '~/graphql/queries/getPage.graphql';

const { variables: defaultVariables }  = useCraftGraphql();

const now = formatISO(Date.now(), { representation: 'date' });

const variables = computed(() => {
    return {
        ...defaultVariables,
        uri: null,
        section: 'home',
        now: ['or', `>= ${now}`, null]
    };
});

const response = await useAsyncQuery({
    query: getPage,
    variables
});

const { craftEntry: entry } = useCraftPageEther(response);

const mainStore = useMainStore();
watchEffect(() => {
    mainStore.toggleNewsletterSubscribe(entry?.value?.showNewsletterSubscribe ?? false);
});

onMounted(() => {
    document.documentElement.classList.add('homepage');
});

onUnmounted(() => {
    document.documentElement.classList.remove('homepage');
});
</script>
